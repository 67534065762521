/* eslint-disable no-unused-vars */
import React, { Component , useRef , useState}  from "react";
import  { Redirect } from 'react-router-dom'

// import  { useRef   }  from "react";
// import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Moment from 'moment';
import validator from 'validator';
import MainLayout from "../layout/main-layout";
import Header from '../layout/Header';
import Left from '../layout/Left';
import DatePicker from "react-datepicker";
import valid from "card-validator";
import "react-datepicker/dist/react-datepicker.css";
import { getClient, getPaymentInfo, setStoreRequest,setShippingCost  } from '../../actions/payActions'
import { set } from "core-js/core/dict";
import NumberFormat from 'react-number-format';



class StemCellRequest extends Component {

    constructor(props){
        super(props);
        this.state={
            error:'',
            errors:{},
            formData:{delivery_speed:"Immediate",shipping_method:"FedEx",Source:'ACT Client Portal',stemskinserum_check:"",stemskinserum:{name:'Stem Cell Face Serum',quantity:0,price:0},Credit_Card_Billing_Zip_Code:"",amount:0,},
            shippindetails:{},
            login:true,                       
            client_test:"",
            client_info:"",
            Dob:'',
            ShipmentDate:"",
            card_last_gid:"",
            card_info:{},
            update_card:false,
            loading:true,
            shipping:0,
            amount:0,
            shipping_info:"false",
            save_card:false,
            verify_client:false,
            Client_id:"",
            Client_lastname:"",
            Infectious_Disease:"",
            shipping_value:"",
            myFutureDate:"",
            disableField:false,
            ip:false,
            minilipo:false,
            bloodwork:false,
            stemskinserum:false,
            minilipo_price:0,
            stemskinserum_price:0,
            Services:[],
            amt:"",
            Payment_Type:"",
            update_card:false,
            update_ach:false,
            save_card:false,
            CreditCard:"",
            
        }

        // window.addEventListener('storage', (e) => this.add_mini_quantity(e));

        // Bind this to storageChanged()
        // this.add_mini_quantity = this.add_mini_quantity.bind(this);
    }

    
  
    componentDidMount() {            
       
        var client_id = localStorage.getItem('client_id'); 

        if(client_id){
            // this.props.getPhysician(physician_id, this.props.history);  
            this.props.getClient(client_id, this.props.history);  

            this.props.getPaymentInfo(client_id, this.props.history);

            if(this.props.match.params.id){
                var DoseData = localStorage.getItem("DoseData")
                DoseData = JSON.parse(DoseData)
             
                console.log("DoseData => ",DoseData)
             
                
                // this.state.formData['Client_id'] = DoseData.clientACTIdHiddenForDose
                this.state.formData['Client_id'] = client_id
                this.setState({Client_id:client_id}) 
                this.state.formData['Client_lastname'] = DoseData.lastName
                this.setState({Client_lastname:DoseData.lastName}) 
                this.state.formData['Longevit_Cells_Availble'] = DoseData.longevityVialsAvailable
                this.state.formData['Free_Bio_Insurance_Cells_Available'] = DoseData.freeBioInsuranceVialsAvailble
            }
            
        }    
        var myCurrentDate=new Date();
        var myFutureDate=new Date(myCurrentDate);
        myFutureDate.setDate(myFutureDate.getDate()+ 3);
        this.setState({myFutureDate:myFutureDate})
         
        var physician_id = localStorage.getItem('physician_id');
        if(physician_id != ""){
            // console.log('imrn')
            this.setState({'physician_id':physician_id})
           
            
        }

        // console.log("param => ",this.props.match.params.id)
        localStorage.getItem('client_id')

        // console.log('form ------ ',this.state.formData);
        
                
    }

    numberFormat = (value) =>
    new Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: 'USD'
    }).format(value);

   
    helloWord = (e) => {
        console.log('hello');
    }



    savecard = (e) => {
        if(this.state.save_card){
            this.setState({save_card:!this.state.save_card})
            this.state.formData['save_card'] = !this.state.save_card
        }
        else{
            this.setState({save_card:true})

            this.state.formData['save_card'] = true
        }
        console.log(this.state.formData);
    }



    onChange = (e) =>{     
        
         if(e.target.id == "shipping_method"){
            
            if(e.target.value=="FedEx"){
                this.setState({shipping:0})
                this.state.formData['amount'] = this.state.amount+ 0;
            }
           
            this.state.formData[e.target.id] = e.target.value;
        }             
        else if(e.target.id == "Client_id"){
            this.state.formData[e.target.id] = e.target.value;
            this.setState({Client_id:e.target.value})
        }
        else if(e.target.id == "Client_lastname"){
            this.state.formData[e.target.id] = e.target.value;
            this.setState({Client_lastname:e.target.value})
        }
        else if(e.target.id == "Credit_Card_Holder_Zip"){
            this.state.formData['Credit_Card_Billing_Zip_Code'] = e.target.value;
           
        }
        else{
            this.state.formData[e.target.id] = e.target.value; 
        }      
        console.log('form data => ',this.state.formData);
    }

    checkRadio3 = (e) => {     
        if(this.state.update_card){
            this.setState({update_card:!this.state.update_card})
            this.state.formData["Credit_Card_Number"] = this.state.card_info['Credit_Card_Number']
            this.state.formData["CVV"] = this.state.card_info['CVV']
            this.state.formData["Credit_Card_Holder_First_Name"] = this.state.card_info['Credit_Card_Holder_First_Name']
            this.state.formData["Credit_Card_Holder_Last_Name"] = this.state.card_info['Credit_Card_Holder_Last_Name']
            this.state.formData["Month_of_Expiry"] = this.state.card_info['Month_of_Expiry']
            this.state.formData["Year_of_Expiry"] = this.state.card_info['Year_of_Expiry']
            
        }
        else{
            this.state.formData["Credit_Card_Number"] = ""
            this.state.formData["CVV"] = ""
            this.state.formData['Credit_Card_Holder_First_Name'] = ""
            this.state.formData['Credit_Card_Holder_Last_Name'] = ""
            this.state.formData['Month_of_Expiry'] = ""
            this.state.formData['Year_of_Expiry'] = ""
            
            

            this.setState({update_card:true})
        }
        
        console.log('update_card =>', this.state.update_card)
        console.log('form data  =>', this.state.formData)
    }

    componentWillReceiveProps(nextProps) {
        console.log('nextProps ----------',nextProps); 

        if(nextProps.page_name.pay_info && this.state.update_card==false){
            this.state.formData['Credit_Card_Holder_First_Name'] = nextProps.page_name.pay_info.Credit_Card_Holder_First_Name__c
            this.state.formData['Credit_Card_Holder_Last_Name'] = nextProps.page_name.pay_info.Credit_Card_Holder_Last_Name__c
            this.state.formData['Credit_Card_Billing_Zip_Code'] = nextProps.page_name.pay_info.Credit_Card_Billing_Zip_Code__c
            this.state.formData['CVV'] = nextProps.page_name.pay_info.CVV__c
            this.state.formData['Credit_Card_Number'] = nextProps.page_name.pay_info.Credit_Card_Number__c
            this.state.formData['Month_of_Expiry'] = nextProps.page_name.pay_info.Month_of_Expiry__c
            this.state.formData['Year_of_Expiry'] = nextProps.page_name.pay_info.Year_of_Expiry__c
            this.state.formData['Account_Number'] = nextProps.page_name.pay_info.Account_Number__c
            this.state.formData['PhysicianFirstName'] = nextProps.page_name.pay_info.First_Name__c
            this.state.formData['PhysicianLastName'] = nextProps.page_name.pay_info.Last_Name__c
            this.state.formData['Name_On_Account'] = nextProps.page_name.pay_info.Name_on_ACH_Account__c
            this.state.formData['ABA_Routing_Number'] = nextProps.page_name.pay_info.ABA_Routing_Number__c
            this.setState({Credit_Card_Number:nextProps.page_name.pay_info.Credit_Card_Number__c})
            // this.setState({card_info:nextProps.page_name.pay_info})
            this.state.formData['ClientId'] = localStorage.getItem('clientACTId');
            this.state.formData['Client_id'] = localStorage.getItem('client_id');

            this.state.card_info['Credit_Card_Number'] = nextProps.page_name.pay_info.Credit_Card_Number__c
            this.state.card_info['CVV'] = nextProps.page_name.pay_info.CVV__c
            this.state.card_info['Credit_Card_Holder_First_Name'] = nextProps.page_name.pay_info.Credit_Card_Holder_First_Name__c
            this.state.card_info['Credit_Card_Holder_Last_Name'] = nextProps.page_name.pay_info.Credit_Card_Holder_Last_Name__c
            this.state.card_info['Month_of_Expiry'] = nextProps.page_name.pay_info.Month_of_Expiry__c
            this.state.card_info['Year_of_Expiry'] = nextProps.page_name.pay_info.Year_of_Expiry__c
            this.state.card_info['PhysicianFirstName'] = nextProps.page_name.pay_info.First_Name__c
            this.state.card_info['PhysicianLastName'] = nextProps.page_name.pay_info.Last_Name__c
            this.state.card_info['Account_Number'] = nextProps.page_name.pay_info.Account_Number__c
            this.state.card_info['Name_On_Account'] = nextProps.page_name.pay_info.Name_on_ACH_Account__c
            this.state.card_info['ABA_Routing_Number'] = nextProps.page_name.pay_info.ABA_Routing_Number__c


            if(nextProps.page_name.pay_info.Account_Number__c){
                var acc_num = nextProps.page_name.pay_info.Account_Number__c;
                this.state.acc_num = acc_num.slice(acc_num.length - 4)
            }
            
            if(nextProps.page_name.pay_info.Credit_Card_Number__c){
                var nu =  nextProps.page_name.pay_info.Credit_Card_Number__c
                this.state.card_last_gid = nu.slice(nu.length - 4)
            }
            this.setState({loading:false})
          } 
        if(nextProps.page_name.page_name){

        var rest = nextProps.page_name.page_name.listResponse[0];
        console.log('rest ----------',rest); 


            // this.state.formData['PhysicianId'] = nextProps.page_name.page_name.Id
            // this.state.formData['Id'] = nextProps.page_name.page_name.Physician_ID__c
            // this.state.formData['PhysicianLastName'] = nextProps.page_name.page_name.LastName 
            this.state.shippindetails['LastName'] = rest.lastName 
            this.state.shippindetails['FirstName'] = rest.firstName 
            this.state.shippindetails['Phone'] = rest.phone 
            this.state.shippindetails['Street'] = rest.preferredAddressStreet 
            this.state.shippindetails['City'] = rest.preferredAddressCity
            this.state.shippindetails['State'] = rest.preferredAddressState
            this.state.shippindetails['Country'] = rest.preferredAddressCountry
            this.state.shippindetails['ZipCode'] = rest.preferredAddressZip
            this.state.shippindetails['Credit_Card_Billing_Email'] = rest.Email
            this.setState({loading:false})
        }        

        if(nextProps.page_name.payment_info){
            this.setState({loading:false})
            this.setState({payment_response:nextProps.page_name.payment_info.page_name})
            this.setState({transaction_id:nextProps.page_name.payment_info.TransactionId})
            this.setState({api_error:nextProps.page_name.payment_info.error})
            this.setState({loading:false})
        }

        if(nextProps.page_name.request_info){
            this.setState({loading:false})
            if(nextProps.page_name.request_info.error){
                this.setState({api_error:nextProps.page_name.request_info.error})
            }else{
                this.setState({payment_response:nextProps.page_name.request_info})
            }
            
            
        }

        console.log('form values ------- ',this.state.formData);

    }

    datechange1(e){
        // new Date(e);
        this.state.formData['ShipmentDate'] = Moment(e).format('MM/DD/YYYY');
        // console.log('selected date =>', this.state.formData['ShipmentDate']);
        
        var selected_day = Moment(e).day();
        let errors = {};
        const yOffset = -80;
        let formIsValid = true;
        if(this.state.formData['shipping_method']=="FedEx"){
            // console.log('selected day before =>', selected_day );
            if(selected_day == 1 || selected_day == 0){
                // console.log('selected day =>', Moment(e).day() );               
                errors["ShipmentDate"] = "FedEx does not deliver on Sunday and Monday. Please choose a different date from Tuesday to Saturday for shipment arrival";
                let element = document.getElementById("ShipmentDate");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"
                
                formIsValid = false;
            }else{
                errors["ShipmentDate"] = "";
                let formIsValid = true;
                
            }
            this.setState({errors: errors});
        }

        this.setState({ShipmentDate:e})
    }

    UseShipping =(e) => {
        var checked = e.target.checked ? "True":"False";
        this.setState({shipping_value:e.target.value})
        if(e.target.value=="yes"){
            this.setState({shipping_info:checked})
            this.state.formData['ShippingInfo'] = checked;
            this.state.formData['FirstName'] = this.state.shippindetails['FirstName'] 
            this.state.formData['LastName'] = this.state.shippindetails['LastName'] 
            this.state.formData['Street'] = this.state.shippindetails['Street']
            this.state.formData['City'] = this.state.shippindetails['City']
            this.state.formData['State'] = this.state.shippindetails['State']
            this.state.formData['Country'] = this.state.shippindetails['Country']
            this.state.formData['ZipCode'] = this.state.shippindetails['ZipCode']
            this.state.formData['Phone'] = this.state.shippindetails['Phone']

            if(this.state.shippindetails['Phone'] == "" || this.state.shippindetails['Phone'] == null ){
                this.state.formData['Phone'] = '8444432355';
            }
            
            
            // console.log("checked ", this.state.formData)
        }else{
            this.setState({shipping_info:checked})
            this.state.formData['ShippingInfo'] = checked;
            this.state.formData['FirstName'] = "" 
            this.state.formData['LastName'] = "" 
            this.state.formData['Street'] = ""
            this.state.formData['City'] = ""
            this.state.formData['State'] = ""
            this.state.formData['Country'] = ""
            this.state.formData['ZipCode'] = ""
            this.state.formData['Phone'] = ""
           
        }

        console.log(this.state.formData);
       
    }


    findShipping =(e)=>{
        var newList = {zip:'302029'};
        this.props.setShippingCost(newList, this.props.history);
    }




    check_quantity = (e) => {

         
        var count = e.target.value;

        if(isNaN(count)){
            e.target.value = 0;
            console.log('ip value --',e.target.value);
        }

    }

   

    add_quantity = (e) => {
       
      

        if(e.target.name == 'stemskinserum'){
            let count = this.state.formData['stemskinserum']['quantity'];
            let amt;
           
            if(isNaN(count)){
                count = 0;
            }else{
                count = count+1;
                // count = parseInt(element.value);
            }

            if(count >= 11){
                amt = 150 * count;

                            
                this.state.formData['stemskinserum-price'] = amt;
                this.state.formData['stemskinserum']['quantity'] = count;
                this.state.formData['stemskinserum']['price'] = amt;
                this.state.formData['stemskinserum_check'] = "true";
                this.state.formData['amount'] =  this.state.formData['stemskinserum']['price'];                 

                
                
                this.state.stemskinserum_price = amt;
                this.state.amt = amt
                this.state.amount =  this.state.formData['stemskinserum']['price'];       
                this.state.stemskinserum = true;       

                this.setState({
                    stemskinserum: true,
                    stemskinserum_price: this.state.formData['mini-lipo-price'],
                    formData: this.state.formData
                 })
                         
              
            }else{
                amt = 175 * count;
                this.state.formData['stemskinserum-price'] = amt;
                this.state.formData['stemskinserum']['quantity'] = count;
                this.state.formData['stemskinserum']['price'] = amt;
                this.state.formData['stemskinserum_check'] = "true";
                this.state.formData['amount'] =   this.state.formData['stemskinserum']['price'];  

                this.state.amt = amt;
                this.state.amount = this.state.formData['stemskinserum']['price'];
                this.state.stemskinserum = true;   
                
                this.setState({
                    stemskinserum: true,
                    stemskinserum_price: amt,
                    formData: this.state.formData
                 })

              
            }
        }


      

        // console.log('ccccc-------',)

        console.log(this.state.formData);
           

    }

    remove_quantity = (e) => {
      

        if(e.target.name == 'stemskinserum'){
            let element = document.getElementById("stemskinserum");
            let count = this.state.formData['stemskinserum']['quantity'];
            let amt;
            if(isNaN(count)){
                count = 0;
                this.state.formData['stemskinserum_check'] = "";
                this.state.stemskinserum = false;
            }else if(count >= 1 ){           
                count -= 1;
                if(count == 0){
                    this.state.formData['stemskinserum_check'] = "";
                    this.setState({
                        stemskinserum: false,
                        formData: this.state.formData
                     })
                }
            }else if(count == 0){
                this.state.formData['stemskinserum_check'] = "";
                this.setState({
                    stemskinserum: false,
                    formData: this.state.formData
                 })
            }

            if(count >= 10){
                amt = 175 * count;
            }else{
                amt = 150 * count;

            }
            this.state.formData['stemskinserum-price'] = amt;   
            this.state.formData['amount'] =   this.state.formData['stemskinserum']['price'];                 

                this.state.formData['stemskinserum']['quantity'] = count;
                this.state.formData['stemskinserum']['price'] = amt;
                this.state.amt = amt
                this.state.amount =  this.state.formData['stemskinserum']['price'];

                this.setState({                    
                    stemskinserum_price: amt,
                    formData: this.state.formData
                 })
        }


        console.log('remove -------', this.state.formData);

    }

    checkAch = (e) => {
        this.setState({update_ach:!this.state.update_ach})
        

        if(this.state.update_ach){
            this.state.formData["Account_Number"] = this.state.card_info['Credit_Card_Holder_Last_Name']
            this.state.formData["Name_On_Account"] = this.state.card_info['Month_of_Expiry']
            this.state.formData["Year_of_Expiry"] = this.state.card_info['Year_of_Expiry']
        }else{
            this.state.formData["Account_Number"] = ""
            this.state.formData["Name_On_Account"] = ""
            this.state.formData["Year_of_Expiry"] = ""
            // this.setState({update_ach:true})

        }
        console.log('update_card =>', this.state.update_ach)


    }

    checkPayment = (e) => {
        if(e.target.id == "credit_card"){
            this.state.formData['CreditCard'] = true; 
            this.setState({CreditCard:e.target.value})
            
            this.state.formData['PaymentMethod'] = 'Credit Card';
            this.state.formData['PaymentMethod'] = 'Credit Card';
            this.state.formData["Credit_Card_Number"] = this.state.card_info['Credit_Card_Number']
            this.state.formData["CVV"] = this.state.card_info['CVV']
            this.state.formData["Credit_Card_Holder_First_Name"] = this.state.card_info['Credit_Card_Holder_First_Name']
            this.state.formData["Credit_Card_Holder_Last_Name"] = this.state.card_info['Credit_Card_Holder_Last_Name']
            this.state.formData["Month_of_Expiry"] = this.state.card_info['Month_of_Expiry']
            this.state.formData["Year_of_Expiry"] = this.state.card_info['Year_of_Expiry']

        }

        if(e.target.id == "shipment"){
            this.state.formData['CreditCard'] = false; 
            this.setState({CreditCard:e.target.value})
            this.state.formData['PaymentMethod'] = 'Check';

            // console.log('CreditCard =>', this.state.CreditCard)
        }

        if(e.target.id == "ACH_payment"){
            this.state.formData['CreditCard'] = false; 
            this.setState({CreditCard:e.target.value})
            this.state.formData['PaymentMethod'] = 'ACH Payment';
            this.state.formData['PaymentMethod'] = 'ACH Payment';
            this.state.formData["Credit_Card_Number"] = ""
            this.state.formData["CVV"] = ""
            this.state.formData['Credit_Card_Holder_First_Name'] = ""
            this.state.formData['Credit_Card_Holder_Last_Name'] = ""
            this.state.formData['Month_of_Expiry'] = ""
            this.state.formData['Year_of_Expiry'] = ""
      


            // console.log('CreditCard =>', this.state.CreditCard)
        }

        this.setState({Payment_Type:e.target.value})
        this.state.formData['Payment_Type'] = e.target.value; 

    }


    onSubmit = (e) => {              
        e.preventDefault();    
        // window.scrollTo(0, 500);
          
        let fields = this.state.formData;
        let errors = {};
        let formIsValid = true;
        // let scroll = {};
        const yOffset = -80;
        // console.log('verify_client ',this.state.verify_client)

       

        if(this.state.shipping_value=="no"){

        if(!fields["Country"]){
            formIsValid = false;
            errors["Country"] = "Please enter Country";
            let element = document.getElementById("Country");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }        
       
        if(!fields["State"]){
            formIsValid = false;
            errors["State"] = "Please enter State";
            let element = document.getElementById("State");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }
        if(!fields["ZipCode"]){
            formIsValid = false;
            errors["ZipCode"] = "Please enter Zip Code";
            let element = document.getElementById("ZipCode");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }

        if(!fields["Phone"]){
            formIsValid = false;
            errors["Phone"] = "Please enter Phone";
            let element = document.getElementById("Phone");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }

        if(isNaN(fields["Phone"]) && fields["Phone"]){
            formIsValid = false;          
            errors["Phone"] = "Please insert number only";
            let element = document.getElementById("Phone");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
         }

        if(!fields["City"]){
            formIsValid = false;
            errors["City"] = "Please enter City";
            let element = document.getElementById("City");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }

        if(!fields["Street"]){
            formIsValid = false;
            errors["Street"] = "Please enter Street";
            let element = document.getElementById("Street");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }
       
        if(!fields["ShippingEmail"]){
            formIsValid = false;
            errors["ShippingEmail"] = "Please enter Shipping Email";
            let element = document.getElementById("ShippingEmail");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }

        if (fields["ShippingEmail"] != "" && !validator.isEmail(fields["ShippingEmail"])) {
            formIsValid = false;
            errors["ShippingEmail"] = "Please enter valid Shipping Email";
            let element = document.getElementById("ShippingEmail");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
          }

        

        if(!fields["FirstName"]){
            formIsValid = false;
            errors["FirstName"] = "Please enter first name";
            let element = document.getElementById("FirstName");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }

        if(!fields["LastName"]){
            formIsValid = false;
            errors["LastName"] = "Please enter last name";
            let element = document.getElementById("LastName");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }

        }   

        if(this.state.shipping_value=="yes"){

            if(this.state.formData['FirstName'] == null || this.state.formData['Street'] == null || this.state.formData['City'] == null || this.state.formData['State'] == null  || this.state.formData['Country'] == null ){
                formIsValid = false;
                errors["use_new_shipping_address"] = "Please select shipping address";
                let element = document.getElementById("use_new_shipping_address");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
            }

        }


       
      


   

    if(this.state.shipping_value==""){
        formIsValid = false;
        errors["use_new_shipping_address"] = "Please select shipping address";
        let element = document.getElementById("use_new_shipping_address");                
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({top: y, behavior: 'smooth'});
       
    }
    

    if(!fields["ShipmentDate"]){
        formIsValid = false;
        errors["ShipmentDate"] = "Please enter Shipment Date";
        let element = document.getElementById("ShipmentDate");                
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({top: y, behavior: 'smooth'});
        element.className = "form-control error"
    } 

    if(fields["ShipmentDate"]){

        var selected_day = Moment(fields["ShipmentDate"]).day();
        if(this.state.formData['shipping_method']=="FedEx"){
            // console.log('selected day before =>', selected_day );
            if(selected_day == 1 || selected_day == 0){
                // console.log('selected day =>', Moment(e).day() );
                
                errors["ShipmentDate"] = "FedEx does not deliver on Sunday and Monday. Please choose a different date from Tuesday to Saturday for shipment arrival";
                let element = document.getElementById("ShipmentDate");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"
                
              
            }
        }

    }

    if(fields["ShipmentDate"]){
        const dateFormat = 'MM-DD-YYYY';
        const yr = new Date(fields["ShipmentDate"]).getFullYear();
        const toDateFormat = Moment(new Date(fields["ShipmentDate"])).format(dateFormat);
        let validDate = Moment(toDateFormat, dateFormat, true).isValid();
        if(!validDate || yr >= 2050){
            formIsValid = false;
            errors["ShipmentDate"] = "Invalid date ";
            let element = document.getElementById("ShipmentDate");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }
    }

    if(!fields["Payment_Type"]){
        formIsValid = false;
        errors["CreditCard"] = "Please Select Payment Method.";
        let element = document.getElementById("credit_card");                
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({top: y, behavior: 'smooth'});
       // element.className = "form-control error"
    } 

      
        

        if(this.state.update_card == true){

            if(!fields["Credit_Card_Number"]){
                formIsValid = false;
                errors["Credit_Card_Number"] = "Please enter credit card number";
                let element = document.getElementById("Credit_Card_Number");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"
            }
            if(fields["Credit_Card_Number"]) {
                let cardvalid = valid.number(fields["Credit_Card_Number"]);
                // console.log('cardvalid',cardvalid);
                if(!cardvalid.isValid){
                    formIsValid = false;
                    errors["Credit_Card_Number"] = "Invalid credit card number.";
                    let element = document.getElementById("Credit_Card_Number");                
                    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"
                }
            }

            if(!fields["CVV"]){
                formIsValid = false;
                errors["CVV"] = "Please enter CVV";
                let element = document.getElementById("CVV");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"
            }

            if(fields["CVV"] && !/^\d{3,4}$/.test(fields["CVV"])) {
                formIsValid = false;
                errors["CVV"] = "Invalid CVV code"; 
                let element = document.getElementById("CVV");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});   
                element.className = "form-control error"            
            }

            if(!fields["Credit_Card_Holder_First_Name"]){
                formIsValid = false;
                errors["Credit_Card_Holder_First_Name"] = "Please enter first name";
                let element = document.getElementById("Credit_Card_Holder_First_Name");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"
            }

            if(!fields["Credit_Card_Holder_Last_Name"]){
                formIsValid = false;
                errors["Credit_Card_Holder_Last_Name"] = "Please enter last name";
                let element = document.getElementById("Credit_Card_Holder_Last_Name");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"
            }

            if(!fields["Credit_Card_Billing_Email"]){
                formIsValid = false;
                errors["Credit_Card_Billing_Email"] = "Please enter billing email";
                let element = document.getElementById("Credit_Card_Billing_Email");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"
            }

            if(fields["Credit_Card_Billing_Email"] && !validator.isEmail(fields["Credit_Card_Billing_Email"])) {
                formIsValid = false;
                errors["Credit_Card_Billing_Email"] = "Invalid email.";
                let element = document.getElementById("Credit_Card_Billing_Email");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error" 
                 
            }

            if(!fields["Month_of_Expiry"]){
                formIsValid = false;
                errors["Month_of_Expiry"] = "Cannot be empty";
                let element = document.getElementById("Month_of_Expiry");                
                    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    window.scrollTo({top: y, behavior: 'smooth'});
                    element.className = "form-control error"
            } 
    
       

            if(!fields["Year_of_Expiry"]){
                formIsValid = false;
                errors["Year_of_Expiry"] = "Cannot be empty";
                let element = document.getElementById("Year_of_Expiry");                
                    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    window.scrollTo({top: y, behavior: 'smooth'});
                    element.className = "form-control error"
                } 
    
                

        }


        // console.log('formIsValid --- ', formIsValid)
        this.setState({errors: errors});

        // console.log('amount => ', this.state.formData['amount'])
        

        if(formIsValid){
            // this.setState({ loading: true });  
    //   this.state.formData['Client_id']  = this.state.client_id;
           
            const newList = this.state.formData;
    

            console.log("newlist=>", newList);
            this.props.setStoreRequest(newList, this.props.history);
            this.setState({loading:true})
        }



    }



    render() {
        const {formData} = this.state
        if(!sessionStorage.getItem('client_id') || sessionStorage.getItem('client_id')==null) {           
            return <Redirect to="../" />
          } 
        return (
            <MainLayout>
                <Header />

                <div className="main-content">
                    <Left activeMenu={"store"}/> 
                    { (this.state.loading)?
                    <div class="loading-div">
                        <div class="loading-img"><img src="../images/spinner.svg" alt="" /></div>
                    </div>:""}

                    <div className="right-section">
                        <div className="pagesubheader-wrap">
                        <div className="pagesubheader-row">
                            <div className="page-subheader-col-left">
                            <div className="title-avatar">
                                <img src="images/physician-icon.svg" alt="" />
                            </div>
                            <div className="title-main">
                                <span className="title-sub-text">Physician Portal</span>
                                <h1>ACT Store</h1>
                            </div>
                            </div>
                        </div>
                        </div>

                        {(this.state.payment_response)?
                        <div>
                            <h5 style={{padding:"10px"}}><i>Please check your email for order confirmation </i></h5>
                        </div>:  
                        <div className="inner-content-area">                      
                        <form className="needs-validation" noValidate onSubmit={this.onSubmit}>
                        
                        <div className="summary-section-wrap">                         
                        <div className="left-section-content">  
                        <span style={{color: "red"}}>{this.state.api_error}</span>
                       
                        <div className="form-wrap">
                         
                            <div className="mid-sections">
                            {/* <h2 className="title-2 mb-4">ACT Store </h2> */}

                            <h6 class="title-3 mb-4">Please Select Items:</h6>
                        

                           

                           

                            <div className="quantity row">
                          <div className="col-md-12"> 

                            <h6>Stem Skin</h6>
                            <p>Stem Cell Face Serum For All Types</p>
                            </div>
                          <div className="col-md-4"> 
                          <img className="card-logo" src="images/StemSkin_Insta_5_a.webp" alt="" />
                            
                          
                          </div>
                          <div className="col-md-8"> 

                            <ul>
                            <li style={{listStyleType: "none"}}>
                                
                            Anti-aging skin serum formulated to activate the skin's natural regeneration abilities to repair and restore damaged skin. Stem Skin contains stem cell-derived growth factors to renew skin and accelerate skin cell regeneration. This concentrated blend of growth factor proteins found in health, young skin plus anti-aging antioxidants can stimulate collagen production that is essential for firm, supple skin. 
                                    <p></p>  
                                
                            </li>
                            

                            <li style={{listStyleType: "none"}}>
                            <b>Ingredients :</b> Water (Aqua), Human Adipose Derived Stem Cell Growth Factors, Glycerin, Polysorbate 20 Aloe Barbadensis Leaf Juice, Camellia Sinensis (Green Tea), Leaf Extract, Xanthan Gum, Phenoxyethanol, Tetrahexyldecyl Ascorbate, Tocopheryl Acetate, Citrus Aurantium Bergamia (Bergamot), Fruit Oil, Lactic Acid, 1, 2-Hexanediol, Caprylyl Glycol
                            <p></p>  
                            
                            </li>
                         

                            <li style={{listStyleType: "none"}}>
                            100% Made in The USA, Non-Comedogenic, Cruelty Free, Non-GMO 
                            </li>

                             </ul>
                            </div>
                          <div className="col-md-12"> 

                            <div >Bottles ($175 per bottle, $150 if ordering 11 or more)</div>
                            <input type="button" value="-" name="stemskinserum"  class="btn minus btn-secondary"  onClick={this.remove_quantity} />
                            <input type="text" id="stemskinserum" name="stemskinserum" value={this.state.formData["stemskinserum"]["quantity"]} class="qty" onBlur={this.check_quantity} />
                            <a class="btn plus btn-primary" name="stemskinserum"  onClick={this.add_quantity} > + </a>
                            </div>
                            <span style={{color: "red"}}>{this.state.errors["Services"]}</span>   
                            </div>
                            <h3 class="title-2"></h3>
                           
                                
                            <h2 className="title-2 mb-4">Shipping Method</h2>
                            <div className="row">
                                <div className="col-md-5">
                                <div className="form-group">
                                    <label>Courier Service</label>
                                    <select className="form-control" disabled id="shipping_method" onChange={this.onChange}>
                                    <option value="FedEx">FedEx</option>
                                 
                                    </select>
                                   
                                </div>
                                </div>                                
                            </div>
                            </div>
                      
                            <div className="mid-sections">
                            <h2 className="title-2 mb-4">Shipping Date</h2>
                            {/* <p>If you have Bio-Insurance or Longevity Cells available, you may order your cells to be shipped on the next available business day if using FedEx Priority or shipped within 24 hours if using the ACT VIP Courier Service
                            </p> */}
                            </div>
                            <div className="col-md-8">
                                <div className="form-group">
                                <label className="col-form-label">Shipment Arrival Date (mm/dd/yyyy) <span className="required">*</span></label>
                                <div className="field-col">
                                <DatePicker id="ShipmentDate" className="form-control bggray" minDate={this.state.myFutureDate} placeholderText="mm/dd/yyyy" selected={this.state.ShipmentDate}  onChange={date => this.datechange1(date)} /> <br />
                                    {/* <input type="text" className="form-control" placeholder="#04/04/2021" /> */}
                                    <span style={{color: "red"}}>{this.state.errors["ShipmentDate"]}</span>
                                </div>
                                
                                </div>
                            </div>
                            <div className="mid-sections">
                            <h2 className="title-2 mb-4">Shipping Information</h2>
                            <div className="saved-address-wrap">
                                <h3 className="title-3">Select saved shipping address</h3>
                                <div className="saved-address custom-control custom-radio" style={{zIndex:"0"}}>
                                <input type="radio" className="custom-control-input" name="shipping_value" id="use_save_shipping_address" value="yes" onChange={this.UseShipping} />
                                {/* <input type="radio" id="customRadio2" name="customRadio" className="custom-control-input" /> */}
                                <label className="custom-control-label" htmlFor="use_save_shipping_address">
                                    <strong>{this.state.shippindetails['FirstName']} {this.state.shippindetails['LastName']}</strong><br />
                                    <span> {this.state.shippindetails['Street']}, {this.state.shippindetails['City']}, {this.state.shippindetails['State']} <br />
                                    {this.state.shippindetails['Country']} {this.state.shippindetails['ZipCode']} {this.state.shippindetails['Phone']} </span>
                                </label>
                                </div>
                            </div>
                            <div className="saved-address-wrap">                            
                                <div className="saved-address custom-control custom-radio">
                                <input type="radio" className="custom-control-input" name="shipping_value" id="use_new_shipping_address" value="no" onChange={this.UseShipping} />
                                <label className="custom-control-label" htmlFor="use_new_shipping_address"> <b>Select new shipping address</b></label>
                               
                                <br />
                                <span style={{color: "red"}}>{this.state.errors["use_new_shipping_address"]}</span>
                                </div>
                            </div>
                            {(this.state.shipping_value=="no")?
                            <div className="new-address-wrap">
                                <h3 className="title-3 mb-5">New shipping address</h3>
                                <div className="form-wrap">
                                <div className="row">
                                <div className="col-md-6">
                                            <div className="form-group">
                                            <label className="col-form-label">First Name <span className="required">*</span></label>
                                            <div className="field-col">
                                                <input type="text" className="form-control" id="FirstName" placeholder="" onChange={this.onChange} />
                                                <span style={{color: "red"}}>{this.state.errors["FirstName"]}</span>
                                            </div>
                                            
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                            <label className="col-form-label">Last Name <span className="required">*</span></label>
                                            <div className="field-col">
                                                <input type="text" className="form-control" id="LastName" placeholder="" onChange={this.onChange} />
                                                <span style={{color: "red"}}>{this.state.errors["LastName"]}</span>
                                            </div>
                                            
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group">
                                            <label className="col-form-label">Shipping Email <span className="required">*</span></label>
                                            <div className="field-col">
                                                <input type="email" className="form-control" id="ShippingEmail" placeholder="" onChange={this.onChange}  />
                                                <span style={{color: "red"}}>{this.state.errors["ShippingEmail"]}</span>
                                            </div>
                                            
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group">
                                            <label className="col-form-label">Shipping Phone <span className="required">*</span></label>
                                            <div className="field-col">
                                                <input type="text" className="form-control" id="Phone" placeholder="" onChange={this.onChange}  />
                                                <span style={{color: "red"}}>{this.state.errors["Phone"]}</span>
                                            </div>
                                            
                                            </div>
                                        </div>
                                        
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="col-form-label">Street <span className="required">*</span></label>
                                                <div className="field-col">
                                                <input type="text" className="form-control" id="Street" placeholder="" onChange={this.onChange} />
                                                <span style={{color: "red"}}>{this.state.errors["Street"]}</span>
                                                </div>
                                                
                                            </div>
                                            </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                            <label className="col-form-label">City <span className="required">*</span></label>
                                            <div className="field-col">
                                                <input type="text" className="form-control" id="City" placeholder="" onChange={this.onChange} />
                                                <span style={{color: "red"}}>{this.state.errors["City"]}</span>
                                            </div>
                                            
                                            </div>
                                        </div>
                                        
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="col-form-label">State <span className="required">*</span></label>
                                                <div className="field-col">
                                                <input type="text" className="form-control" id="State" placeholder="" onChange={this.onChange} />
                                                <span style={{color: "red"}}>{this.state.errors["State"]}</span>
                                                </div>
                                                
                                            </div>
                                            </div>
                                        

                                        <div className="col-md-6">
                                            <div className="form-group">
                                            <label className="col-form-label">Zip Code <span className="required">*</span></label>
                                            <div className="field-col">
                                                <input type="text" className="form-control" id="ZipCode" placeholder="" onChange={this.onChange} />
                                                <span style={{color: "red"}}>{this.state.errors["ZipCode"]}</span>
                                            </div>
                                            
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                            <label className="col-form-label">Country <span className="required">*</span></label>
                                            <div className="field-col">
                                            <input type="text" className="form-control" id="Country" placeholder="" onChange={this.onChange} />
                                            <span style={{color: "red"}}>{this.state.errors["Country"]}</span>
                                            </div>
                                            
                                            </div>
                                        </div>
                                    
                                </div>
                                </div>
                            </div>:""}

                            </div>

                        <div className="row" >
                            <div class="submission-detail-sec col-md-12">

                            <h3 class="title-3 mb-4">Please Choose a Payment Method :</h3>
                            <div class="choose-options-sec">
                            <div class="custom-control custom-radio">
                            <input type="radio" id="credit_card" name="CreditCard" class="custom-control-input" value="CC" onClick={this.checkPayment} />
                            <label class="custom-control-label" for="credit_card">Credit Card</label>
                            </div>
                        
                            <div class="custom-control custom-radio">
                            <input type="radio" id="ACH_payment" name="CreditCard" class="custom-control-input" value="ACH" onClick={this.checkPayment}/>
                            <label class="custom-control-label" for="ACH_payment">ACH Payment</label>
                            </div>                                        
                            </div>
                            <span style={{color: "red"}}>{this.state.errors["CreditCard"]}</span>
                            <br />
                          
                            {(this.state.Payment_Type == "CC")? 

                            
                                    <div className="card">
                                        <div className="card-header">
                                        <h4>Current Payment Method Secured For Services </h4>
                                        <div className="custom-control custom-checkbox">
                                            <input type="checkbox" className="custom-control-input" id="customCheck2" defaultChecked />
                                            <label className="custom-control-label" htmlFor="customCheck2" />
                                        </div>
                                        </div>
                                        <div className="card-body">
                                        <div className="saved-card-detail">
                                            <div className="row">
                                            <div className="col-md-6">
                                                <label>Card Number</label>
                                                <div className="card-dtl-value">
                                                <img className="card-logo" src="images/master-card.png" alt="" />
                                                <span>Credit Card ending in {this.state.card_last_gid}</span>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <label>Name on card</label>
                                                <div className="card-dtl-value">
                                                <span>{this.state.card_info['Credit_Card_Holder_First_Name']} {this.state.card_info['Credit_Card_Holder_Last_Name']}</span>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <label>Expiry</label>
                                                <div className="card-dtl-value">
                                                <span>{this.state.card_info['Month_of_Expiry']} / {this.state.card_info['Year_of_Expiry']}</span>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                        <br />
                                        <div className="custom-control custom-checkbox">
                                            <input type="checkbox" id="update_card" name="update_card" className="custom-control-input" value="true" onClick={this.checkRadio3}/>
                                            <label className="custom-control-label" for="update_card">Use Another Credit Card </label>
                                          
                                        </div>

                                        {(this.state.update_card == true)?
                                        <div className="card-edit-option">
                                            <div className="card-detail-form">
                                            <div className="card-detail-wrap mb-5">
                                                <h5>Credit Card Detail <img src="images/info.svg" data-toggle="tooltip" data-placement="top" title alt="" data-original-title="Tooltip Text" /></h5>
                                                <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                    <label>Credit Card Number <span className="required">*</span> </label>
                                                    <input type="text" id="Credit_Card_Number" className="form-control" placeholder="Card number" onChange={this.onChange} />
                                                    <span style={{color: "red"}}>{this.state.errors["Credit_Card_Number"]}</span>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                    <label>CVV <span className="required">*</span> </label>
                                                    <input type="password" id="CVV" className="form-control" placeholder="***" onChange={this.onChange} />
                                                    <span style={{color: "red"}}>{this.state.errors["CVV"]}</span>
                                                    </div>
                                                </div>
                                                {/* <div className="col-md-3">
                                                    <div className="form-group">
                                                    <label>Expiry Date <span className="required">*</span> </label>
                                                    <input type="text" className="form-control" placeholder="-- --  /  -- -- -- --" />
                                                    <span style={{color: "red"}}>{this.state.errors["CreditCard"]}</span>
                                                    </div>
                                                </div> */}

                                            <div className="col-md-6 mb-3">
                                            <label htmlFor="Month_of_Expiry">Expiration Date <span className="act_required">*</span></label>
                                            <select className="custom-select d-block w-100" id="Month_of_Expiry" onChange={this.onChange}  placeholder="mm" >
                                                <option value="">Select Month</option>
                                                <option value="01">01</option>
                                                <option value="02">02</option> 
                                                <option value="03">03</option> 
                                                <option value="04">04</option> 
                                                <option value="05">05</option>    
                                                <option value="06">06</option> 
                                                <option value="07">07</option> 
                                                <option value="08">08</option> 
                                                <option value="09">09</option> 
                                                <option value="10">10</option> 
                                                <option value="11">11</option>
                                                <option value="12">12</option>  
                                            </select>
                                            {/* <input type="text" className="form-control" id="Month_of_Expiry" onChange={this.onChange} placeholder="mm" required /> */}
                                            <span style={{color: "red"}}>{this.state.errors["Month_of_Expiry"]}</span>
                                            <div className="invalid-feedback">
                                            Valid Month required
                                            </div>
                                            </div>

                                            <div className="col-md-6 mb-3">
                                            <label htmlFor="creditCardExpirationYear"> &nbsp;</label>
                                            <select className="custom-select d-block w-100" id="Year_of_Expiry" onChange={this.onChange}  placeholder="YYYY" >
                                            <option value="">Year</option>                                            
                                          
                                            <option value="2025">2025</option>
                                            <option value="2026">2026</option>
                                            <option value="2027">2027</option>
                                            <option value="2028">2028</option>
                                            <option value="2029">2029</option>
                                            <option value="2030">2030</option>
                                            <option value="2031">2031</option>
                                            <option value="2032">2032</option>
                                            </select>
                                            {/* <input type="text" className="form-control" id="creditCardExpirationYear" onChange={this.onChange} placeholder="yy" required /> */}
                                            
                                            <span style={{color: "red"}}>{this.state.errors["Year_of_Expiry"]}</span><div className="invalid-feedback">
                                            Valid Year required
                                            </div>
                                            </div>


                                                
                                                <div className="col-md-12">
                                                    <div className="card-logo-list">
                                                    <div><img src="../images/card-1.svg" alt="" /></div>
                                                    <div><img src="../images/card-2.svg" alt="" /></div>
                                                    <div><img src="../images/card-3.svg" alt="" /></div>
                                                    <div><img src="../images/card-4.svg" alt="" /></div>
                                                    </div>
                                                </div>
                                                </div>
                                                <div>                                            
                                                    <div className="custom-control custom-checkbox">
                                                    <br />
                                                    <input type="checkbox" id="save_card" name="save_card" className="custom-control-input" value="true" onClick={this.savecard}/>
                                                    <label className="custom-control-label" for="save_card">Save this credit card on file for future payments</label>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div className="card-detail-wrap">
                                                <h5>Billing Address <img src="images/info.svg" data-toggle="tooltip" data-placement="top" title alt="" data-original-title="Tooltip Text" /></h5>
                                                <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                    <label>First Name</label>
                                                    <input type="text" id="Credit_Card_Holder_First_Name" className="form-control" placeholder onChange={this.onChange} />
                                                    <span style={{color: "red"}}>{this.state.errors["Credit_Card_Holder_First_Name"]}</span>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                    <label>Last Name</label>
                                                    <input type="text" id="Credit_Card_Holder_Last_Name" className="form-control" placeholder onChange={this.onChange} />
                                                    <span style={{color: "red"}}>{this.state.errors["Credit_Card_Holder_Last_Name"]}</span>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                    <label>Billing Email</label>
                                                    <input type="text" id="Credit_Card_Billing_Email" className="form-control" placeholder onChange={this.onChange} />
                                                    <span style={{color: "red"}}>{this.state.errors["Credit_Card_Billing_Email"]}</span>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                    <label>Zip/Postal Code</label>
                                                    <input type="text" id="Credit_Card_Holder_Zip"  className="form-control" placeholder onChange={this.onChange} />
                                                    <span style={{color: "red"}}>{this.state.errors["Credit_Card_Holder_Zip"]}</span>

                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                        :""}
                                        {/* <div className="act-total">
                                             Total: $ {(this.state.amount)+(this.state.shipping)+(this.state.cpp_price)}
                                        </div> */}

                                        </div>
                                    </div>
  
                            :""}

{(this.state.Payment_Type == "ACH")?    
                             <div className="card">
                                <div className="card-header">
                                <h4>ACH Payment Method Secured For Banking </h4>   
                                                                    
                                </div>  
                                <div className="card-body">
                                    <div className="saved-card-detail">                     
                                    <div class="row">          
                                        {/* <h2 className="title-2">ACH Billing Information</h2> */}
                                        <div class="col-md-4">
                                        <label> Name On Account</label>
                                        <div class="card-dtl-value">
                                        <span>{this.state.card_info['Name_On_Account']}</span>
                                        </div>
                                        </div>             
                                        <div class="col-md-4">
                                        <label>Account Number</label>
                                        <div class="card-dtl-value">
                                        <span>Account Number Ending in {this.state.acc_num}
                                        </span>
                                        </div>
                                        </div>

                                        <div class="col-md-4">
                                        <label>ABA Routing Number</label>
                                        <div class="card-dtl-value"> 
                                        <span>{this.state.card_info['ABA_Routing_Number']}
                                        </span>
                                        </div>
                                        </div>
                                    
                                    
                                    </div>
                                    </div>

                                    <br />
                                    <div lassName="custom-control">
                                        {(!this.state.card_info['ABA_Routing_Number'] ||  !this.state.card_info['Account_Number'])?
                                        <span><b>Please store your ACH Details in the <a href="./payment-information">update payment information</a> tab.</b></span>
                                        
                                        :""}

                                    </div>
                                    {/* <div className="custom-control custom-checkbox">
                                        <input type="checkbox" id="update_ach" name="update_ach" className="custom-control-input" value="true" onClick={this.checkAch}/>
                                        <label className="custom-control-label" for="update_ach">Use Another ACH </label>
                                        
                                    </div> */}

                                    {(this.state.update_ach == true)?
                                        <div className="card-edit-option">   
                                            <div className="card-detail-form">
                                            <div class="row">
                                         <div class="col-md-6">
                                             <div class="form-group">
                                                 <label>Name On Account <span class="required">*</span> </label>
                                                 <input type="text" id="Name_on_account" class="form-control" placeholder="" onChange={this.onChange} />
                                                 <span style={{color: "red"}}>{this.state.errors["Name_on_account"]}</span>
                                             </div>
                                         </div>
                                         <div class="col-md-6">
                                             <div class="form-group">
                                                 <label>Account Number<span class="required">*</span> </label>
                                                 <input type="text" id="Name_on_account" class="form-control" placeholder="" onChange={this.onChange} />
                                                 <span style={{color: "red"}}>{this.state.errors["Name_on_account"]}</span>
                                             </div>
                                         </div>
                                         <div class="col-md-6">
                                             <div class="form-group">
                                                 <label>ABA Routing Number <span class="required">*</span> </label>
                                                 <input type="text" id="Name_on_account" class="form-control" placeholder="" onChange={this.onChange} />
                                                 <span style={{color: "red"}}>{this.state.errors["Name_on_account"]}</span>
                                             </div>
                                         </div>

                                        </div>
                                            </div>
                                        </div>
                                    :""}
                                </div>
                            </div>
                                :''} 


                            </div>
                    

                        </div>

                            <button type="submit" className="btn btn-primary">Submit</button><br />
                            <span style={{color: "red"}}>{this.state.api_error}</span>
                        </div>
                        </div>
                       {/* order summery */}
                       <div class="order-summary col-md-4" >
                        <div class="order-summary-inner">
                            <div class="order-summary-row plan-font-size pb-4">
                            <div class="order-label">
                                <small>Order Summary</small>
                            <h2>ACT Store Order</h2>
                          
                            </div>

                            <div class="summary-value pt-40">
                                {/* Demo 01 */}
                            </div>
                            </div>

                            <div class="order-summary-row">
                            <div class="order-label" style={{width:"150px"}}>
                            Items 
                            </div>
                            <div class="order-label">
                            Qty
                            </div>
                            <div class="order-label">                           
                            Amount
                            </div>
                            </div>

                            {(this.state.ip)?
                            <div class="order-summary-row">
                            <div class="order-label" style={{width:"150px"}}>
                            Initial Processing
                            </div>
                            <div class="summary-value">                           
                                {(this.state.formData['ip']['quantity'])} 
                            </div>
                            <div class="summary-value">                           
                                {this.numberFormat(this.state.formData['ip']['price'])}
                            </div>
                            </div>:""}

                            {(this.state.bloodwork)?
                            <div class="order-summary-row">
                            <div class="order-label" style={{width:"150px"}}>
                            Bloodwork
                            </div>
                            <div class="summary-value">                           
                                {(this.state.formData['bloodwork']['quantity'])}  
                            </div>
                            <div class="summary-value">                           
                                {this.numberFormat(this.state.formData['bloodwork']['price'])}
                            </div>
                            </div>:""}

                                                
                            

                            {(this.state.stemskinserum)?
                            <div class="order-summary-row">
                            <div class="order-label" style={{width:"167px"}}>
                            Stem Cell
                            <p>Face Serum</p>
                            </div>
                            <div class="summary-value">                           
                                {(this.state.formData['stemskinserum']['quantity'])}  
                            </div>
                            <div class="summary-value">                           
                                {this.numberFormat(this.state.formData['stemskinserum']['price'])}
                            </div>
                            </div>:""}
                       
                            <div class="order-summary-row">
                            <div class="order-label">
                            Shipping
                            </div>
                            <div class="summary-value">                           
                                {this.numberFormat(this.state.shipping)}
                                {/* <NumberFormat thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'$'} value={this.state.shipping}/> */}
                            </div>
                            </div>
                    

                        

                            <div class="order-summary-row total-saving">
                            <div class="order-label">
                            <i><img src="images/saving-icon.png" alt="" /></i>  Total Price 
                            </div>
                            <div class="summary-value">
                                {/* ${(this.state.amount)+(this.state.shipping)+(this.state.cpp_price)} */}
                                {(this.state.formData['stemskinserum']['price'] > 0 )? 
                                this.numberFormat((this.state.formData['amount'])):
                                this.numberFormat(this.state.shipping)
                            }
                            </div>                      
                            </div>
                    
                            {/* <img style={{marginTop:"15px"}} className="card-logo" src="images/ACT Bloodwork Instructions.JPG" alt="" /> */}
                        </div>

                  
                  
                             </div>
                      
                        </div>                        
                        </form>
                        </div>
                        }
                        </div>

                </div>
            </MainLayout>
        );
    }
}

StemCellRequest.propTypes = {
    errors: PropTypes.object.isRequired,
    error: PropTypes.object.isRequired,
    setStoreRequest: PropTypes.object.isRequired,
    getClient: PropTypes.object.isRequired,
    getPaymentInfo: PropTypes.object.isRequired,
    // GetPhysicianVialsService: PropTypes.object.isRequired,     
};

const mapStateToProps = (state) => ({
    page_name : state.pay,       
    errors: state.errors,
  });

  export default connect(mapStateToProps, {getClient,getPaymentInfo,setStoreRequest  })(StemCellRequest);


// export default StemCellRequest;