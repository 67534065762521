import React, { Component, useRef } from 'react';
import  { Redirect } from 'react-router-dom'
import {
	BrowserRouter as Router,
	Route,
	Switch,
	BrowserRouter,
	useHistory
} from 'react-router-dom';
import PropTypes from 'prop-types';
import IdleTimer from 'react-idle-timer'


class Header extends Component {
  // var history = useHistory();

  constructor(props) {
    super(props)
    this.idleTimer = null
    this.handleOnAction = this.handleOnAction.bind(this)
    this.handleOnActive = this.handleOnActive.bind(this)
    this.handleOnIdle = this.handleOnIdle.bind(this)
    this.state ={
      mobilemenu:"",
    }
  }

  handleOnAction (event) {
    // console.log('user did something', event)
  }

  handleOnActive (event) {
    console.log('user is active', event)
    console.log('time remaining', this.idleTimer.getRemainingTime())
  }

  handleOnIdle (event) {
    console.log('user is idle', event)
    console.log('last active', this.idleTimer.getLastActiveTime())
  }

  signout = (e) => {
    // const { history } = this.props;
    localStorage.setItem('client_id',null)
    localStorage.setItem('ClientLastName',null)
    localStorage.setItem('clientACTId',null)
    sessionStorage.clear();
    console.log('logount call')
    window.location = '../';
    // history.push('../home')
  }
    render() {
      let activeMenu = this.props.activeMenu == undefined ? '' : this.props.activeMenu;
        return (
            <div className="main-header">
              <IdleTimer
                ref={ref => { this.idleTimer = ref }}
                timeout={1000 * 60 * 5}
                // onActive={this.handleOnActive}
                onIdle={this.signout}
                // onAction={this.handleOnAction}
                debounce={250}
              />
            <div className="container-fluid">
            <div className="main-header-left">
              <div className="main-logo-area">
                <a href><img src="../images/logo-main.svg" alt="" /></a>
              </div> 
            </div>
            
            <div className="header-inner"> 

              <div className="main-title-top">
                Client Portal
              </div>
  
              <div className="main-header-mid">
                {/* <div className="input-group">
                  <div className="input-group-prepend">
                    <button type="button" className="btn btn-outline-secondary dropdown-toggle" data-toggle="dropdown">
                     <span>All</span>
                    </button>
                    <div className="dropdown-menu">
                      <a className="dropdown-item" href>Link 1</a>
                      <a className="dropdown-item" href>Link 2</a>
                      <a className="dropdown-item" href>Link 3</a>
                    </div>
                  </div>
                  <div className="search-input">
                    <i><img src="images/icon_search.png" alt="" /></i>
                    <input type="search" className="form-control" placeholder="Search Data..." />
                  </div>
                </div> */}
              </div>
              <div className="main-header-right">
              {/* <div className="mob-nav">
                  <a className=" d-flex dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" href ><img src="/images/menu.png" alt="" /></a>
                  <div className="dropdown-menu">
                  <ul className="left-nav" style={{fontSize:'10px;'}}>
                      <li  className={ activeMenu == 'client-information' ? " active" : ''}><a href="../client-information">Client Information</a></li>
                      <li  className={ activeMenu == 'client-activity' ? "active" : ''}><a  href="../client-activity">Client Activity</a></li>
                      <li className='dropdown-submenu'>
                        <a className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="true" href=''>Forms and Other Documents</a>
                        
                                  <ul class="">
                                      <li style={{marginTop:'5px'}}><a href="../form-document/Consent-form">Consent Form</a></li>
                                      <li><a href="../form-document/Infectious-disease">Infectious Disease</a></li>
                                  </ul>
    
                      </li>
                      <li className={ activeMenu == 'stem-cell-request' ? "active" : ''}><a href="../stem-cell-request">Stem Cell Request Form</a></li>
                      <li className={ activeMenu == 'ACT-banking-storage-plans' ? "active" : ''}><a href="/ACT-banking-storage-plans">ACT Banking and Storage Plans</a></li>
                      <li className={ activeMenu == 'payment-information' ? "active" : ''}><a href="../payment-information">Payment Information</a></li>
                      </ul>
                    </div>
              </div> */}
                  <div className="settings-dropdown mr-3">
                   
                     <a className=" d-flex dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" href ><img src="/images/menu.png" alt="" /></a>
                    {/* <div className="dropdown-menu"> */}
                      <ul className="dropdown-menu">
                      <li  className={ activeMenu == 'client-information' ? " active dropdown-item" : 'dropdown-item'}><a href="../client-information">Client Information</a></li>
                      <li  className={ activeMenu == 'client-activity' ? "active dropdown-item" : 'dropdown-item'}><a  href="../client-activity">Client Activity</a></li>
                       <li className='dropdown-submenu dropdown-item'>
                        <a className="dropdown-toggle dropdown-item" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="true" href=''>Forms and Other Documents</a>
                        
                                  <ul class="">
                                      <li className="dropdown-item" style={{marginTop:'5px'}}><a href="../form-document/Consent-form">Consent Form</a></li>
                                      <li className="dropdown-item"><a href="../form-document/Infectious-disease">Infectious Disease</a></li>
                                  </ul>
    
                      </li>
                   
                      <li className={ activeMenu == 'stem-cell-request' ? "active dropdown-item" : 'dropdown-item'}><a href="../stem-cell-request">Stem Cell Request Form</a></li>
                      <li className={ activeMenu == 'ACT-banking-storage-plans' ? "active dropdown-item" : 'dropdown-item'}><a href="/ACT-banking-storage-plans">ACT Banking and Storage Plans</a></li>
                    <li className={ activeMenu == 'payment-information' ? "active dropdown-item" : 'dropdown-item'}><a href="../payment-information">Payment Information</a></li>

                      </ul>                    
                    {/* </div> */}
                  </div>
                  <div className="notification-col main-profile-menu">
                    <a className="profile-user d-flex dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" href ><img alt="" src="../images/avatar1.jpg" /></a>
                    <div className="dropdown-menu">
                        <a className="dropdown-item" href="../client-information"><i className="mdi mdi-account-circle-outline"></i>Profile</a>
                        <a className="dropdown-item" href="../client-information"><i className="mdi mdi-cog-outline"></i>Edit Profile</a>
                        <a className="dropdown-item sign-out-link" onClick={this.signout} href><i className="mdi mdi-logout-variant"></i>Sign Out</a>
                    </div>
                  </div>
                </div>

                </div>
                </div>
              </div>
        );
    }
}

// Header.propTypes = {

// };

export default Header;