import axios from "axios";
// import  { Redirect } from 'react-router-dom'

import { GET_ERRORS, SET_PAY,MAKE_PAY,SET_LOGIN,SET_IPActivity,SET_PAY_INFO,GET_PAY,CLIENT_ACTIVITY,SET_SHIPPING_AMT, SET_DISEASE,SET_PHYSICIAN,SET_CONSENT,GET_OTP,SET_PASS,SET_REQUEST,SET_VIAL,SET_DOSE ,SET_CLIENT} from "./types";





// Edit Country
export const GetClientVialsService = (formdata) => dispatch => {
  console.log('hello')
  axios
    .post("/api/pay/get_ClientVialsService",formdata)    
    .then(res => {
      console.log(res);
    	dispatch({ type: SET_VIAL, payload : res.data })
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response
      })
    );
};



export const verifyClientData = (formdata) => dispatch => {
  console.log('hello')
  axios
    .post("/api/pay/set_verifyClientData",formdata)    
    .then(res => {
      console.log(res);
    	dispatch({ type: SET_CLIENT, payload : res.data })
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response
      })
    );
};

export const getShippingAmount = (formdata) => dispatch => {
  // let access_token = '00D3j0000002ViU!AQ4AQMG6ro790YwPGRjfdGAgHv1TIRJkDc4kmfKjNNc8IIfrx9VtisG3J2cOkTNYH8I904UwIgvwPeVRMm4qJHdKNB.EFktM';
  console.log('hello')
  
  axios
  .post("/api/pay/get_shipping_amount",formdata) 
    .then(res => {
      console.log(res);
    	dispatch({ type: SET_SHIPPING_AMT, payload : res.data })
    })
    .catch(err =>
        dispatch({
        type: GET_ERRORS,
        payload: err.response
      })
    );
};



export const getOtp = (data) => dispatch => {
  console.log('client_email => ',data)
  axios
    .get("/api/pay/get_otp",{ params: {firstName:data.firstName, dateOfBirth:data.dateOfBirth, email: data.email } })    
    .then(res => {
      console.log(res);
    	dispatch({ type: GET_OTP, payload : res.data })
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response
      })
    );
};




// Edit Country
export const getClient = (id) => dispatch => { 
  axios
    .get("/api/pay/get_Client",{params: {id:id}} )    
    .then(res => {
      console.log(res.data);
    	dispatch({ type: SET_PAY, payload :res.data})
      let resp = res.data.result
        console.log('respo ', resp.listResponse[0]) 
        // console.log('respo login ', resp.login) 
        if(resp.listResponse[0].clientACTId){
          localStorage.setItem('clientACTId',resp.listResponse[0].clientACTId);
          localStorage.setItem('ClientLastName',resp.listResponse[0].lastName);          
          localStorage.setItem('initialProcessingFormCompleted',resp.listResponse[0].initialProcessingFormCompleted);
          localStorage.setItem('infectiousDiseaseFormCompleted',resp.listResponse[0].infectiousDiseaseFormCompleted);
          localStorage.setItem('clientConsentFormCompleted',resp.listResponse[0].clientConsentFormCompleted);
          localStorage.setItem('consentForm',resp.listResponse[0].clientConsentFormCompleted);
          localStorage.setItem('DiseaseForm',resp.listResponse[0].infectiousDiseaseFormCompleted);
          localStorage.setItem('creditCardNumber',resp.listResponse[0].creditCardNumber);

          
        }  
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response
      })
    );
};

export const getPhysician = (id) => dispatch => { 
  axios
    .get("/api/pay/get_Client",{params: {id:id}} )    
    .then(res => {
      console.log(res.data);
    	dispatch({ type: SET_CLIENT, payload :res.data})
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response
      })
    );
};


export const getClientActivity = (id) => dispatch => { 
  axios
    .get("/api/pay/get_ClientActivity",{params: {id:id}})    
    .then(res => {
      console.log(res.data);
    	dispatch({ type: CLIENT_ACTIVITY, payload :res.data})
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response
      })
    );
};

export const updatePassword = (formdata) => dispatch => { 
  axios
    .post("/api/pay/update_Password",formdata)    
    .then(res => {
      console.log(res.data);
    	dispatch({ type: SET_PASS, payload :res.data})
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response
      })
    );
};



export const updateClient = (formdata) => dispatch => { 
  axios
    .post("/api/pay/update_Client",formdata)    
    .then(res => {
      console.log(res.data);
    	dispatch({ type: SET_PHYSICIAN, payload :res.data})
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response
      })
    );
};

export const updatePhysician = (formdata) => dispatch => { 
  axios
    .post("/api/pay/update_Physician",formdata)    
    .then(res => {
      console.log(res.data);
    	dispatch({ type: SET_PHYSICIAN, payload :res.data})
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response
      })
    );
};

export const sendProcessData = (formdata) => dispatch => { 
  axios
    .post("/api/pay/send_ProcessData",formdata)    
    .then(res => {
      console.log(res.data);
    	dispatch({ type: MAKE_PAY, payload :res.data})
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response
      })
    );
};

export const StoragePlan = (formdata) => dispatch => { 
  axios
    .post("/api/pay/send_StoragePlan",formdata)    
    .then(res => {
      console.log(res.data);
    	dispatch({ type: MAKE_PAY, payload :res.data})
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response
      })
    );
};

export const sendConsentData = (formdata) => dispatch => { 
  axios
    .post("/api/pay/send_ConsentData",formdata)    
    .then(res => {
      console.log(res.data);
    	dispatch({ type: SET_CONSENT, payload :res.data})
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response
      })
    );
};



export const setCellRequest = (formdata) => dispatch => { 
  axios
    .post("/api/pay/send_CellRequest",formdata)    
    .then(res => {
      console.log(res.data);
    	dispatch({ type: SET_REQUEST, payload :res.data})
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response
      })
    );
};




export const getPaymentInfo = (id) => dispatch => {
  axios
    .get("/api/pay/get_PaymentInfo",{params: {id:id}})    
    .then(res => {
      console.log(res.data);
    	dispatch({ type: GET_PAY, payload :res.data})
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response
      })
    );

}

export const setStoreRequest = (formdata) => dispatch => { 
  axios
    .post("/api/pay/send_CP_StoreRequest",formdata)    
    .then(res => {
      console.log(res.data);
    	dispatch({ type: SET_REQUEST, payload :res.data})
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response
      })
    );
};


export const setPaymentInfo = (formdata) => dispatch => { 
  axios
    .post("/api/pay/set_PaymentInfo",formdata)    
    .then(res => {
      console.log(res.data);
    	dispatch({ type: SET_PAY_INFO, payload :res.data})
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response
      })
    );
};


// Add Country
export const login = (loginData, history) => dispatch => {
  
  // const [page_name] = useState(0);
  axios
    .post("/api/pay/login", loginData)
    .then(res => 
      {
        

        

        console.log('res data on payaction page  ', res.data.result);


        
        let resp1 = res.data.result;

        console.log('res data on payaction page  2', resp1.id);

        // let resp = JSON.parse(res.data.result);

        if(resp1.login || resp1.login == "true"){

          console.log('inside login con');
          localStorage.setItem('client_id',resp1.id);
          localStorage.setItem('clientACTId',resp1.clientACTId);
          localStorage.setItem('clientLastName',resp1.clientLastName);

          sessionStorage.setItem('client_id',resp1.id);
          sessionStorage.setItem('clientACTId',resp1.clientACTId);
          sessionStorage.setItem('clientLastName',resp1.clientLastName);
          // sessionStorage.setItem("isUserLogged", false);
          history.push('/client-information');

        }  

        dispatch({ type: SET_LOGIN, payload : res.data });


      })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response
      })
    );
};

export const getPatientDoseActivity = (id) => dispatch => {
  axios
    .get("/api/pay/get_PatientDoseActivity",{params: {id:id}}) 
    .then(res => {
      console.log(res.data);
    	dispatch({ type: SET_DOSE, payload :res.data})
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response
      })
    );

}


export const SearchDoseActivity = (searchData) => dispatch => {
  axios
    .post("/api/pay/SearchDoseActivity",searchData) 
    .then(res => {
      console.log(res.data);
    	dispatch({ type: SET_DOSE, payload :res.data})
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response
      })
    );

}

export const getPatientIPActivity = (id) => dispatch => {
  axios
    .get("/api/pay/get_PatientIPActivity",{params: {id:id}})  
    .then(res => {
      console.log(res.data);
    	dispatch({ type: SET_IPActivity, payload :res.data})
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response
      })
    );

}

// Add Country
export const addPayment = (paymentData, history) => dispatch => {
  // const [page_name] = useState(0);
  axios
    .post("/api/pay/checkout", paymentData)
    .then(res => 
      {
        // this.setState({page_name:'congrats'}),
        dispatch({ type: MAKE_PAY, payload : res.data })
        // history.push("/")
      })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response
      })
    );
};




export const setDiseaseData = (formData, history) => dispatch => {
  // const [page_name] = useState(0);
  axios
    .post("/api/pay/set_DiseaseData", formData)
    .then(res => 
      {
        // this.setState({page_name:'congrats'}),
        dispatch({ type: SET_DISEASE, payload : res.data })
        // history.push("/")
      })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response
      })
    );
};


export const addAgreement = (formData, history) => dispatch => {
  // const [page_name] = useState(0);
  axios
    .post("/api/pay/agreement", formData)
    .then(res => 
      {
        // this.setState({page_name:'congrats'}),
        dispatch({ type: MAKE_PAY, payload : res.data })
        // history.push("/")
      })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response
      })
    );
};






